@mixin background-border($background, $border, $radius) {
  background-color: $background;
  border: $border;
  border-radius: $radius;
}

@mixin patient-activity-titles($color, $padding, $font) {
  color: $color;
  padding: $padding;
  font-size: $font;
}

@mixin patient-activity-ratings($align, $padding, $color, $font) {
  align-self: $align;
  padding-top: $padding;
  color: $color;
  font-weight: $font;
}

.patient-container {
  width: 100%;
  min-height: 300px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  &__personal {
    background: #fcfcfc;
    display: flex;
    flex-direction: column;
    padding: 25px 40px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    .title-avatar {
      display: flex;
      align-items: center;
      height: 80px;
      margin-bottom: 16px;
      .avatar {
        width: 80px;
        height: 80px;
        margin-right: 16px;
      }
      .edit-pers-info {
        cursor: pointer;
        color: #8093fc;
        font-size: 24px;
        margin: 0 0 auto auto;
        &:hover {
          color: darken(#8093fc, 5%);
        }
      }
      .name-line {
        font-weight: 600;
        font-size: 20px;
        color: #606060;
        margin: 0;
      }
      .diagnostic-title {
        font-size: 14px;
        color: #606060;
        margin-bottom: 0;
        span {
          margin-left: 5px;
          font-weight: 600;
          font-size: 14px;
          color: #606060;
        }
      }
      .user-subscription {
        margin-top: 8px;
        font-size: 14px;
        font-weight: bold;
        &_none {
          color: #ea7dae;
        }
        &_active {
          background-color: #ea7dae;
          color: #fff;
          padding: 4px 6px;
          border-radius: 4px;
          margin-right: 4px;
        }
      }
      &__name {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 4px;
      }
    }
    .info {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      .info-item {
        flex: 0 1 30%;
        margin-right: 25px;
        .info-label {
          font-size: 12px;
          color: #bdbdbd;
          margin-bottom: 0;
        }
        .info-color {
          width: 20px;
          height: 20px;
          border-radius: 20px;
          border: 0.5px solid;
          margin-right: 5px;
        }
        .info-color-value {
          display: flex;
          flex-direction: row;
        }
        .info-value {
          font-size: 14px;
          color: #606060;
          &.dni {
            font-weight: 600;
            color: #8093fc;
          }
          &.gender {
            margin-left: 12px;
            font-weight: 600;
          }
        }
      }
    }
  }
  &__edit {
    background: #fcfcfc;
    padding: 25px 40px;
    &__title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      p {
        font-weight: 600;
        font-size: 18px;
        color: #606060;
        margin-bottom: 0px;
      }
      .edit-pers-info-close-icon {
        cursor: pointer;
        color: #606060;
        font-size: 30px;
        &:hover {
          color: darken(#8093fc, 5%);
        }
      }
    }
    .form {
      display: grid;
      grid-column-gap: 1rem;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
    .edit-pers-info-buttons {
      display: flex;
      align-items: center;
    }
  }
  &__tabs {
    position: relative;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 40px;
    background: #fcfcfc;
    .tab {
      z-index: 10;
      border-radius: 4px 4px 0px 0px;
      padding: 8px 10px;
      cursor: pointer;
      font-size: 14px;
      color: #606060;
      &.active {
        background: #f2f4ff;
        font-weight: 600;
        color: #8093fc;
        border-bottom: 3px solid #8093fc;
        &:hover {
          z-index: 10;
        }
      }
      &.unactive {
        font-weight: normal;
        border: none;
        background-color: transparent;
        color: #606060;
        cursor: default;
        opacity: 0.5;
      }
      &:hover {
        z-index: 0;
        background: #f2f4ff;
      }
      &.disabled {
        font-weight: normal;
        border: none;
        background-color: transparent;
        color: #606060;
        cursor: not-allowed;
        opacity: 0.2;
      }
    }
    .down {
      position: absolute;
      bottom: 0;
      width: 92%;
      height: 2px;
      border-radius: 100px;
      background-color: #d9d9d9;
    }
  }
}

.without-profile {
  @extend .patient-container;
}

.unselected-patient {
  display: flex;
  flex-direction: column;
  align-items: center;
  @extend .patient-container;
  padding: 50px;
  p {
    margin-bottom: 10px;
    &:first-child {
      font-size: 24px;
      text-align: center;
      font-weight: bold;
      color: #616261;
    }
    &:nth-child(2) {
      max-width: 300px;
      font-size: 14px;
      text-align: center;
      color: #bdbdbd;
    }
  }
  &__image {
    width: 350px;
    height: 450px;
    img {
      width: 100%;
      height: auto;
      display: block;
      object-fit: cover;
    }
  }
}

.patient {
  &__tabbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-radius: 10px 10px 0px 0px;
    .tab {
      z-index: 1;
      height: 100%;
      padding-top: 12px;
      padding-bottom: 16px;
      padding-left: 30px;
      padding-right: 30px;
      border-bottom: 1px solid #cbe6e0;
      color: #bdbdbd;
      margin-top: 10px;
      border-radius: 10px 10px 0px 0px;
      background-color: #fff;
      font-size: 14px;
      cursor: pointer;
      &.active {
        border: 1px solid #cbe6e0;
        border-bottom: 1px solid #f9faff;
        background-color: #f9faff;
        font-weight: bold;
        color: #8093fc;
        &:hover {
          border-bottom: 1px solid #f9faff;
          background-color: #f9faff;
          color: #8093fc;
        }
      }
      &:hover {
        color: darken(#bdbdbd, 5%);
        border-bottom: 1px solid #cbe6e0;
        background-color: darken(#fff, 2%);
      }
    }
    .tab-space {
      z-index: 1;
      flex-grow: 1;
      border-bottom: 1px solid #cbe6e0;
      border-right: 1px solid #cbe6e0;
      height: 100%;
      padding-top: 12px;
      padding-bottom: 13px;
      margin-top: 10px;
      color: #fff;
      background-color: #fff;
    }
    .tab-init {
      width: 35px;
      border-bottom: 1px solid #cbe6e0;
      height: 100%;
      padding-top: 12px;
      padding-bottom: 13px;
      margin-top: 10px;
      color: #fff;
      background-color: #fff;
    }
  }
  &__container {
    max-height: 768px;
    padding: 15px 40px 10px 20px;
    overflow-y: auto;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    &_text {
      display: flex;
    }
    &_select {
      max-width: 280px;
    }
    .title {
      display: inline-block;
      font-weight: 600;
      font-size: 18px;
      color: #606060;
      margin-bottom: 0;
      transform: translateY(2px);
    }
    .icon {
      font-size: 32px;
      color: #495fd7;
      margin-right: 16px;
    }
    &__download-button {
      cursor: pointer;
      color: #495fd7;
      font-size: 22px;
    }
  }
  &__tab_content {
    margin-left: 30px;
    table {
      width: 100%;
      display: table !important;
      height: auto;
      thead {
        background-color: #65dfb6;
        border-radius: 10px;
        padding-top: 12px;
        padding-bottom: 12px;
        display: block !important;
        width: 100%;
        margin-bottom: 10px;
        tr {
          display: flex !important;
          width: 100%;
          th {
            text-align: center;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            color: #ffffff;
          }
        }
      }
      tbody {
        // border: 1px solid #cbe6e0;
        display: block !important;
        width: 100%;
        border-radius: 10px;
        tr {
          display: flex !important;
          width: 100%;
          margin-bottom: 8px;
          margin-top: 8px;
          background-color: #fcfcfc;
          &.empty-state {
            margin: 0px !important;
            height: 100%;
            margin: 0px;
            background-color: #f9faff;
            td {
              padding: 0px;
              text-align: left;
            }
            &:hover {
              background-color: #f9faff;
            }
          }
          td {
            width: 100%;
            text-align: center;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            color: #616261;
            padding-top: 12px;
            padding-bottom: 12px;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }
    &.assessment {
      width: 100%;
      display: flex;
      flex-direction: row;
      .column {
        width: 40%;
        margin-right: 5%;
      }
      .assessment-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 45% 45%;
        grid-column-gap: 15%;
        margin-right: 5%;
        @media (max-width: 1024px) {
          grid-column-gap: 10%;
        }
      }
      .item {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
        .item-label {
          margin-bottom: 5px;
          font-size: 14px;
          color: #bdbdbd;
        }
        .item-value {
          margin-bottom: 0;
          font-weight: normal;
          font-size: 14px;
          color: #030303;
          &.bigtext {
            max-height: 100px;
            height: 100px;
            background-color: #fff;
            border: 1px solid #cbe6e0;
            font-weight: normal;
            font-size: 14px;
            padding: 6px;
            border-radius: 4px;
            overflow-x: scroll;
          }
          &--link {
            @extend .item-value;
            color: #8093fc;
          }
        }
        &:last-child {
          margin-bottom: 0px;
        }
        .tags {
          display: row;
          flex-direction: column;
          width: 100%;
          .tag {
            background-color: #8093fc;
            color: #f9faff;
            font-weight: normal;
            font-size: 14px;
            border-radius: 10px;
            padding: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            margin-right: 8px;
          }
        }
      }
    }
    &.plans {
      display: flex;
      flex-direction: column;
      height: 100%;
      .patients__no-results {
        .content {
          &.empty-state {
            max-width: 47% !important;
            margin-left: -40px;
          }
        }
      }
      .edit-plan-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          margin-left: auto;
        }
      }
    }
    .button-container {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
    }
    .add-followup-button {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__logs {
    border: 1px solid #d9d9d9;
    display: block !important;
    width: 100%;
    border-radius: 10px;
    height: 45vh;
    max-height: 45vh;
    overflow-y: scroll;
    background-color: #fff;
    margin-right: 50px;
    padding-top: 10px;
    .item {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      margin-top: 10px;
      background-color: #fafafa;
      padding-top: 4px;
      padding-bottom: 4px;
      &--invited {
        opacity: 0.6;
        cursor: default;
      }
      &:hover {
        background-color: #8093fc;
        &::before {
          background-color: #fff;
        }
        .info {
          .title,
          .date {
            color: #fff;
          }
        }
      }
      &::before {
        content: '';
        margin: -22px 15px 0 30px;
        width: 8px;
        height: 8px;
        vertical-align: middle;
        display: inline-block;
        border-radius: 50%;
        background-color: #6fcf97;
      }
      .info {
        display: flex !important;
        flex-direction: column;
        margin-top: 4px;
        margin-bottom: 4px;
        .title {
          font-weight: bold;
          font-size: 16px;
          color: #616261;
          margin-bottom: 3px;
        }
        .date {
          font-weight: normal;
          font-size: 14px;
          color: #bdbdbd;
          margin-bottom: 0px;
        }
      }
      .events {
        display: flex;
        justify-content: space-between;
        width: 60px;
        margin: 0 20px 0 auto;
        .event-detail {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #495fd7;
          background: #ced5fd;
          font-size: 18px;
          width: 25px;
          height: 25px;
          padding-right: 1px;
          border-radius: 3px;
        }
      }
    }
  }
  &__modal {
    position: relative;
    .observations-field {
      font-size: 14px;
      line-height: 21px;
      color: #616261;
      font-weight: normal;
    }
    .close-icon-container {
      width: 100%;
      text-align: right;
      .close-icon {
        font-size: 28px;
        cursor: pointer;
        color: #616261;
        margin-right: -25px !important;
        margin-top: 5px;
        &:hover {
          color: #495fd7;
        }
      }
    }
    &.form {
      .title {
        margin-top: 20px;
        margin-bottom: 40px;
      }
      .form__error {
        margin-top: 5px !important;
      }
      .form-line {
        display: flex;
        flex-direction: row;
        .form__input-container {
          &:first-child {
            margin-right: 20px;
          }
          .form__error {
            margin-top: 25px !important;
          }
        }
      }
      .button-container {
        margin-top: 50px;
      }
    }
    &_label {
      font-weight: bold;
      font-size: 16px;
      color: #616261;
      margin: 0;
    }
    &_timestamp {
      font-size: 12px;
      color: #8093fc;
    }
    .answered-group {
      border-bottom: 1px solid rgba(217, 217, 217, 0.8);
      margin-bottom: 20px;
    }
    .group-title {
      @extend .title;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &_title {
        margin-bottom: 0;
      }
      &_points {
        font-size: 16px;
        font-weight: bold;
        color: #46c2ad;
        margin-bottom: -2px;
        margin-left: 5px;
        &--total {
          margin-bottom: 15px;
          font-size: 20px;
        }
      }
    }
    .info {
      width: 100%;
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      .item {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        .label {
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #818181;
          margin-bottom: 10px;
        }
        .value {
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          color: #616261;
          margin-bottom: 0px;
          &.bigtext {
            max-height: 80px;
            height: 80px;
            background-color: #fff;
            border: 1px solid #cbe6e0;
            font-weight: normal;
            padding: 6px;
            border-radius: 4px;
            overflow-x: scroll;
          }
          &-link {
            @extend .value;
            color: #8093fc;
          }
        }
        .tags {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .tag {
            background-color: #8093fc;
            color: #f9faff;
            font-size: 14px;
            border-radius: 10px;
            padding: 5px 10px;
            margin-right: 8px;
            margin-bottom: 5px;
          }
        }
      }
    }
    &_followups {
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        position: relative;
        &::before {
          position: absolute;
          content: '\2022';
          color: #46c2ad;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }
  &__subtitle {
    font-size: 16px;
    margin-bottom: 20px;
    color: #495fd7;
    font-weight: 700;
  }
  &__booking-select {
    width: 35%;
    &_length {
      font-size: 14px;
      color: #616261;
      margin: 0;
    }
    label {
      font-weight: normal;
      font-size: 14px;
      color: #616261;
    }
  }
  &__call-logs {
    border: 1px solid #cbe6e0;
    display: block !important;
    width: 100%;
    border-radius: 10px;
    height: 50vh;
    max-height: 50vh;
    overflow-y: scroll;
    background-color: #fff;
    margin-right: 50px;
    padding-top: 10px;
    .item {
      display: flex !important;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
      margin-top: 10px;
      background-color: #fcfcfc;
      padding-top: 4px;
      padding-bottom: 4px;
      cursor: pointer;
      &:hover {
        background-color: darken(#fcfcfc, 2%);
        .info {
          .title,
          .date {
            color: #616261;
          }
        }
        .content {
          .message {
            color: #616261;
          }
        }
      }
      &::before {
        content: '';
        margin-right: 15px;
        margin-left: 30px;
        margin-top: 12px;
        width: 8px;
        height: 8px;
        vertical-align: middle;
        display: inline-block;
        border-radius: 50%;
        background-color: #bdbdbd;
      }
      &:last-child {
        &::before {
          background-color: #6fcf97;
        }
      }
      .info {
        display: flex !important;
        flex-direction: column;
        margin-top: 4px;
        margin-bottom: 4px;
        margin-right: 40px;
        .title {
          font-weight: bold;
          font-size: 16px;
          color: #616261;
          margin-bottom: 3px;
        }
        .date {
          font-weight: normal;
          font-size: 14px;
          color: #bdbdbd;
          margin-bottom: 0px;
        }
      }
      .content {
        margin-top: 4px;
        margin-bottom: 4px;
        display: flex;
        flex: 1;
        align-items: center;
        .message {
          font-weight: normal;
          font-size: 14px;
          color: #bdbdbd;
          margin-bottom: 0;
        }
      }
      .actions {
        width: 23%;
        margin-left: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        .status {
          margin-bottom: 0;
          color: #c1c5d7;
          font-weight: bold;
          &.confirmed {
            color: #6fcf97;
          }
          &.rejected {
            color: #f39090;
          }
        }
        button {
          margin-right: 10px;
        }
      }
    }
  }
}

.activity {
  &__content {
    display: flex;
    &_metrics {
      display: flex;
      justify-content: space-between;
    }
    &_chart {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      .ReactChart {
        padding: 0 10px 20px 0;
        margin-bottom: 20px;
        height: 100%;
      }
    }
    &_left-side {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 20px;
      tbody {
        background: transparent !important;
        color: white !important;
        tr {
          background: transparent !important;
          padding: 5px 10px 5px 10px !important;
          margin: 0px !important;
          td {
            color: white !important;
          }
        }
      }
      .last-session {
        color: #616261;
        font-size: 14px;
      }
      .current-day {
        color: #616261;
        font-size: 14px;
        margin-bottom: 0px;
      }
      .patient-plans {
        &__title {
          margin-left: 20px;
          color: #65dfb6;
          span {
            color: #616261;
            font-size: 14px;
          }
        }
      }
      .metrics-item {
        min-width: calc(33% - 20px);
        max-width: calc(33% - 20px);
        width: calc(33% - 20px);
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        -webkit-box-shadow: 2px 2px 4px 0px rgba(199, 199, 199, 0.5);
        -moz-box-shadow: 2px 2px 4px 0px rgba(199, 199, 199, 0.5);
        box-shadow: 2px 2px 4px 0px rgba(199, 199, 199, 0.5);
      }
      .metric-item-icon {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 20px;
        max-width: 20px;
        min-width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.last-login {
          background-color: #495fd7;
        }
        &.plan-state {
          background-color: #ffda95;
        }
        &.pain-level {
          background-color: #46c2ad;
        }
        .icon {
          color: #fff;
          font-size: 28px;
        }
      }
      .metric-item-data {
        width: calc(90% - 20px);
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .metric-item-title {
        margin: 0;
        color: #ababab;
        font-size: 14px;
        line-height: 16px;
      }
      .metric-item-value {
        margin: 0px;
        color: #616261;
        font-weight: 700;
        font-size: 16px;
        span {
          font-size: 14px;
          padding-left: 5px;
          color: #717271;
        }
      }
    }
    &_logsbtn {
      font-weight: 600;
      font-size: 14px;
      color: #495fd7;
    }
    &_logstitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 10px;
      margin-bottom: 16px;
      border-bottom: 1px solid #717271;
      h3 {
        font-weight: 600;
        font-size: 16px;
        color: #495fd7;
        margin-bottom: 0;
      }
      svg {
        font-size: 24px;
        cursor: pointer;
      }
    }
    &_right-side {
      position: relative;
      padding-left: 20px;
      ul {
        height: calc(95vh - 100px);
        overflow-y: auto;
        list-style-type: none;
        li {
          position: relative;
          padding-left: 16px;
          .comment {
            font-weight: bold;
            color: #616261;
            cursor: pointer;
            .inner-data {
              font-weight: 300;
              font-size: 14px;
            }
            .comment-button {
              display: block;
              font-weight: 600;
              font-size: 14px;
              color: #495fd7;
            }
            .custom-width {
              max-width: 450px !important;
            }
          }
          .date {
            margin-left: 15px;
            color: lighten(#bdbdbd, 10%);
            font-size: 15px;
            margin-top: -10px;
          }
          &::after {
            content: '';
            position: absolute;
            top: 8px;
            left: 2px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #65dfb6;
          }
        }
      }
    }
  }
}
.patients__no-results {
  transform: translateX(-1px);
}
.highlight-color {
  color: #8093fc;
}
.call__modal {
  .call-message-field {
    width: 100%;
    border: 1px solid #cbe6e0;
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #818181;
    height: 150px !important;
  }
  label {
    color: #818181;
    font-size: 14px;
    font-weight: bold;
  }
  .invalid-feedback {
    display: none !important;
  }
}
.handle-call-buttons {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
.button-call-complete {
  margin-left: 15px;
}

.pagination-patient-plan {
  margin-top: -40px !important;
}

.select-survey-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.send-survey {
  font-weight: bold;
  font-size: 18px;
  color: #606060;
}

.select-all {
  max-width: 250px;
  margin-top: 10px;
  align-self: flex-end;
}

.group {
  padding-bottom: 20px;
  height: 60vh;
  overflow-y: scroll;
  &__content {
    margin-bottom: 30px;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(217, 217, 217, 0.8);
    }
  }
  &__question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(217, 217, 217, 0.5);
    }
    &_label {
      font-size: 14px;
      color: #818181;
      margin: 0;
    }
  }
}

.css-9f5dsg-menu {
  z-index: 11 !important;
}

/*.css-26l3qy-menu {
  div {
    max-height: 200px;
  }
}*/
