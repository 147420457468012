.patients-view {
  display: flex;
  width: 100%;
  .patients-container {
    position: relative;
    background: #fcfcfc;
    width: 30%;
    max-width: 470px;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    &--collapsed {
      width: 110px;
    }
    .arrow-container {
      border: 0;
      position: absolute;
      top: 340px;
      right: -25px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: #495fd7;
      background: #fff;
      box-shadow: 0px 4px 10px rgba(35, 38, 48, 0.09);
      font-size: 20px;
    }
    .current-status {
      background: #46c2ad;
      width: 100%;
      height: 40px;
      padding-right: 16px;
      padding-left: 34px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &--collapsed {
        padding-right: 7px;
        padding-left: 15px;
      }
      &__text {
        display: flex;
        align-items: center;
        &_user {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #46c2ad;
          background: #e0f5f1;
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        .added {
          margin-bottom: 0;
          margin-left: 6px;
          font-weight: normal;
          font-size: 14px;
          color: #fff;
        }
        .limit-added {
          color: #4b390f;
        }
      }
      &_number {
        color: #fff;
        margin-bottom: 0;
        font-size: 18px;
      }
      .limit-number {
        color: #4b390f;
      }
    }
    .patients-list {
      height: calc(100vh - 70px);
      overflow-y: scroll;
      padding: 10px 16px 20px 34px;
      &--collapsed {
        padding: 10px 24px 34px;
      }
    }
    .limit {
      background: #ffbb6a;
      &__user {
        background: #4b390f;
        color: #ffbb6a;
      }
    }
    &__companies {
      width: 280px;
      height: 35px;
      margin: 0 auto;
      .css-yk16xz-control {
        height: 100%;
        min-height: 35px;
      }
      .css-1wa3eu0-placeholder {
        color: #e0e0e0;
        font-size: 14px;
        margin-top: -2px;
      }
    }
  }
  .selected-patient {
    width: 67%;
    max-width: 1500px;
    margin: 20px auto;
    &--collapsed {
      width: 82%;
    }
    @media (min-width: 1920px) {
      margin: 20px 40px;
    }
  }
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
