.create-notification {
  padding: 40px 20px 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(414px, 1fr));
  &__fields {
    max-width: 95%;
    margin-bottom: 20px;
  }
  &__list {
    &__filters {
      display: grid;
      justify-items: center;
      align-items: center;
      grid-template-columns: 1fr 1fr auto;
      gap: 6px;
      section {
        width: 100%;
      }
      .css-yk16xz-control {
        height: 35px;
        min-height: 35px;
      }
      .css-1wa3eu0-placeholder {
        color: #e0e0e0;
        font-size: 14px;
        margin-top: -2px;
      }
      &_select {
        display: flex;
        align-items: center;
        font-size: 26px;
        width: 20px;
        height: 20px;
        margin: 0 10px;
        color: #bdcbf8;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        &--selected {
          border: 2px solid #bdcbf8;
        }
      }
    }
    .super-clinic-employees {
      height: 550px;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      padding: 8px;
      margin-top: 10px;
    }
  }
}
